import React, { FunctionComponent } from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "../Link";
import Picture from "../Picture";
import "./styles.scss";
import { ImageInterface, PortableText } from "../../types/SanityTypes";
import BlockContent from "@sanity/block-content-to-react";
import YouTubeVideoComponent from "../YoutubeVideoComponent";

export interface HeroBannerInterface {
  heading: string;
  headingQuotes?: boolean;
  description?: string;
  descriptionContent: PortableText;
  _rawFeaturePromoImage?: ImageInterface;
  youTubeVideoBlock?: {
    _rawCoverImage: ImageInterface;
    video: string;
  };
  featurePromoImage?: ImageInterface;
  ctaLabel?: string;
  isExternalLink?: boolean;
  externalCtaLink?: string;
  ctaLink?: {
    slug?: {
      current: string;
    };
  };
  bgColor: {
    title: string;
    value: string;
  };
  contentAlignment?: string;
  preferPerformance?: boolean;
  tabIndex?: number;
}

const HeroBanner: FunctionComponent<HeroBannerInterface> = ({
  _rawFeaturePromoImage,
  youTubeVideoBlock,
  featurePromoImage,
  bgColor,
  contentAlignment,
  heading,
  headingQuotes,
  description,
  descriptionContent,
  ctaLabel,
  isExternalLink,
  externalCtaLink,
  ctaLink,
  preferPerformance = false,
  tabIndex = 0
}) => {
  const image = _rawFeaturePromoImage || featurePromoImage;
  const themeColor = bgColor?.title?.toLowerCase();
  const bannerImage = {
    src: `${image?.asset?.url}`,
    alt: `${image?.alt}`,
    sources: [
      {
        srcset: [
          `${image?.asset?.url}?w=700&h=700&fit=fill&auto=format&q=80 1x`,
          `${image?.asset?.url}?w=1400&h=1400&fit=fill&auto=format&q=80 2x`
        ],
        media: `(max-width: 639px)`
      },
      {
        srcset: [
          `${image?.asset?.url}?w=600&h=600&fit=fill&auto=format&q=80 1x`,
          `${image?.asset?.url}?w=1200&h=1200&fit=fill&auto=format&q=80 2x`
        ],
        media: `(min-width: 640px) and (max-width: 1023px)`
      },
      {
        srcset: [
          `${image?.asset?.url}?w=720&h=720&fit=fill&auto=format&q=80 1x`,
          `${image?.asset?.url}?w=1440&h=1440&fit=fill&auto=format&q=80 2x`
        ],
        media: `(min-width: 1024px) and (max-width: 1439px)`
      },
      {
        srcset: [
          `${image?.asset?.url}?w=1000&h=1000&fit=fill&auto=format&q=80 1x`,
          `${image?.asset?.url}?w=2000&h=2000&fit=fill&auto=format&q=80 2x`
        ],
        media: `(min-width: 1440px)`
      }
    ],
    maintainAspectRatio: false,
    lqip: image?.asset?.metadata?.lqip
  };

  return (
    <Card
      className={`c-hero-banner c-hero-banner--${themeColor} ${image ? "text-left" : "text-center"} ${
        contentAlignment === "left" ? "c-hero-banner--position-left" : ""
      } rounded-0 border-0`}
    >
      {youTubeVideoBlock && (
        <YouTubeVideoComponent
          altVideo={youTubeVideoBlock._rawCoverImage?.alt || bannerImage.alt}
          coverImage={image || youTubeVideoBlock._rawCoverImage}
          video={youTubeVideoBlock.video}
        />
      )}
      {image && !youTubeVideoBlock && (
        <Card.Header className="p-0 border-0 rounded-0 c-hero-banner__header">
          <Picture
            src={bannerImage.src}
            alt={bannerImage.alt}
            sources={bannerImage.sources}
            maintainAspectRatio={bannerImage.maintainAspectRatio}
            lqip={bannerImage.lqip}
            inView={true}
            loading={preferPerformance ? "eager" : "lazy"}
          />
        </Card.Header>
      )}
      <Card.Body>
        <Container
          className={`c-hero-banner__content ${headingQuotes ? "c-hero-banner__content--quotes my-4 my-sm-0" : ""}`}
        >
          {heading && <h2 className="mb-0">{heading}</h2>}
          {description && <Card.Text className="py-2">{description}</Card.Text>}
          {descriptionContent && (
            <BlockContent
              className={`${headingQuotes ? "quote-description" : "banner-text"}`}
              serializers={{
                marks: {
                  underline: props => <span className="underline-text">{props.children}</span>
                }
              }}
              blocks={descriptionContent}
            />
          )}
          {isExternalLink && ctaLabel && !headingQuotes ? (
            <Link
              to={externalCtaLink}
              asButton={themeColor}
              className="d-inline-block text-uppercase"
              type="external"
              rel="noreferrer"
              title="Links opens in a new window"
              data-analytics-event78
              aria-label={ctaLabel}
              tabIndex={tabIndex}
            >
              {ctaLabel}
            </Link>
          ) : (
            ctaLink &&
            ctaLabel &&
            !headingQuotes && (
              <Link
                _id={heading}
                to={
                  ctaLink?.parentSection
                    ? `${ctaLink.parentSection?.slug.current}/${ctaLink.slug.current}`
                    : `${ctaLink.slug?.current}`
                }
                asButton={themeColor}
                className="d-inline-block"
                tabIndex={tabIndex}
              >
                {ctaLabel}
              </Link>
            )
          )}
        </Container>
      </Card.Body>
    </Card>
  );
};

export default HeroBanner;
